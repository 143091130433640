import { useEffect } from 'react';
import { themeChange } from 'theme-change';
import { Link } from '@remix-run/react';
import { IconExport } from '@fastly/beacon-icons';
import {
  SiX,
  SiLinkedin,
  SiDevdotto,
  SiMastodon,
} from '@icons-pack/react-simple-icons';
import { LogoWithFastly } from './LogoWithFastly';
import './footer.css';

const Footer = () => {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-info">
          <Link to="/" aria-label="Glitch homepage">
            <LogoWithFastly />
          </Link>
          <p>Build fast, full-stack web apps in your browser for free</p>
          <div className="theme-toggle">
            <label htmlFor="theme">Choose a theme:</label>
            <select
              className="theme-select select"
              name="theme"
              id="theme"
              data-choose-theme
            >
              <option value="">Default</option>
              <option value="dark">Dark</option>
            </select>
          </div>
        </div>
        <div>
          <h4>Company</h4>
          <ul>
            <li>
              <Link to="https://glitch.com/about">About Us</Link>
            </li>
            <li>
              <Link to="https://glitch.com/about/press">Press</Link>
            </li>
            <li>
              <Link to="https://www.fastly.com/about/careers">Careers</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Community</h4>
          <ul>
            <li>
              <Link to="https://help.glitch.com/hc/en-us">Help Center</Link>
            </li>
            <li>
              <Link to="https://support.glitch.com/" target="_blank">
                Forum <IconExport />
              </Link>
            </li>
            <li>
              <Link to="http://status.glitch.com/" target="_blank">
                Service Status <IconExport />
              </Link>
            </li>
            <li>
              <Link to="https://glitch.com/legal">Legal/Terms</Link>
            </li>
            <li>
              <Link to="https://glitch.com/pricing">Pricing</Link>
            </li>
            <li>
              <Link
                to="https://help.glitch.com/hc/en-us/requests/new?ticket_form_id=16166010089869"
                target="_blank"
              >
                Report Abuse <IconExport />
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Create with...</h4>
          <ul>
            <li>
              <Link to="https://glitch.com/digitalocean">DigitalOcean</Link>
            </li>
            <li>
              <Link to="https://glitch.com/fastly">Fastly</Link>
            </li>
            <li>
              <Link to="https://glitch.com/webxr">Web XR</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Social</h4>
          <ul>
            <li>
              <Link to="https://twitter.com/glitch">
                <SiX />
                @glitch
              </Link>
            </li>
            <li>
              <Link to="https://mastodon.social/@glitchdotcom">
                <SiMastodon />
                @glitchdotcom
              </Link>
            </li>
            <li>
              <Link to="https://twitter.com/fastlydevs">
                <SiX />
                @fastlydevs
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/fog-creek-software">
                <SiLinkedin />
                LinkedIn
              </Link>
            </li>
            <li>
              <Link to="https://dev.to/glitch">
                <SiDevdotto />
                DEV.to Profile
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
