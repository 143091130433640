import { useFetcher } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './invisible-recaptcha.css';

const RECAPTCHA_KEY = '6LdUHssbAAAAAMXgxxWiD2oaH3O-F0KUyJRTIGQa';

// When we do not have a logged in user, we need an anonymous user.
// For the `getAnonymousUser` endpoint, we need a valid ReCAPTCHA value.
// So we use an invisible ReCAPTCHA, invoke it programatically, and then submit that
// to our get-anonymous-user action
const InvisibleRecaptcha = () => {
  const reCaptchaRef = useRef(null);
  const fetcher = useFetcher();
  const [hasSubmitted, updateHasSubmitted] = useState(false);

  useEffect(() => {
    if (reCaptchaRef.current && fetcher.state === 'idle' && !hasSubmitted) {
      const submitRecaptcha = async () => {
        try {
          //@ts-ignore-next-line TS does not know about the functions available on the ReCaptcha
          const recaptcha = await reCaptchaRef?.current?.executeAsync();
          fetcher.submit(
            { recaptcha, path: window.location.pathname },
            { method: 'post', action: '/get-anonymous-user/action' },
          );
          updateHasSubmitted(true);
        } catch {
          console.error('Recaptcha failed');
        }
      };
      submitRecaptcha();
    }
  }, [reCaptchaRef, fetcher, updateHasSubmitted]);

  return (
    <ReCAPTCHA ref={reCaptchaRef} size="invisible" sitekey={RECAPTCHA_KEY} />
  );
};

export default InvisibleRecaptcha;
