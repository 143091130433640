import Header from './Header';
import Footer from './Footer';
import type { User } from '~/models/user.server';
import type { Team } from '~/models/team.server';
import Banner from '../shared/Banner';

export function Layout({
  user,
  teams,
  children,
}: {
  user?: User;
  teams?: Team[];
  children: React.ReactNode;
}) {
  return (
    <>
      <Header user={user} teams={teams} />
      <Banner
        text="This is the all-new version of Glitch! 🤠"
        cta={{
          href: 'https://support.glitch.com/c/building-glitch/29',
          text: 'Share your thoughts',
        }}
      />
      <main>{children}</main>
      <Footer />
    </>
  );
}
