import { Link } from '@remix-run/react';
import './banner.css';
import { IconArrowRight } from '@fastly/beacon-icons';

type BannerTypes = {
  cta: {
    href: string;
    text: string;
  };
  text: string;
};
const Banner = ({ cta, text }: BannerTypes) => {
  return (
    <div className="banner">
      {text}{' '}
      <Link target="_blank" to={cta.href} className="link">
        {cta.text}
        <IconArrowRight />
      </Link>
    </div>
  );
};

export default Banner;
