export const LogoWithFastly = () => (
  <svg fill="none" height="49" viewBox="0 0 153 49" width="153">
    <clipPath id="a">
      <path d="m0 0h153v49h-153z" />
    </clipPath>
    <g clipPath="url(#a)">
      <g fill="#7a848d">
        <path d="m50.5916 36.0043v8.1289h-1.1035l-4.1737-5.963h-.0712v5.963h-1.2488v-8.1289h1.1865l4.1737 5.963h.0742v-5.963z" />
        <path d="m54.8365 44.2743c-.5331.001-1.0591-.1208-1.5366-.3556-.414-.292-.7641-.6639-1.0293-1.0933-.2534-.5177-.3776-1.0882-.3619-1.6634.0019-.5744.1251-1.142.3619-1.6663.2018-.4729.5666-.8593 1.0293-1.0904.464-.2702.9996-.3952 1.5366-.3585.5334-.0014 1.0598.1214 1.5366.3585.4145.2902.7648.6612 1.0293 1.0904.2405.5258.3648 1.0964.3648 1.6737 0 .5772-.1243 1.1478-.3648 1.6736-.2209.4649-.5856.8479-1.0412 1.0933-.4846.1996-1.0005.314-1.5247.338zm0-1.0022c.3439.0133.682-.0904.9581-.2939.2564-.2031.4345-.4874.5043-.8052.1306-.349.1803-.7227.1454-1.0933-.0108-.3674-.0595-.7327-.1454-1.0903-.132-.289-.3015-.5598-.5043-.8053-.2697-.2157-.6126-.3209-.9581-.2939-.3439-.013-.6819.0907-.9582.2939-.2532.2059-.4305.4891-.5043.8053-.1305.348-.1802.7207-.1453 1.0903.0119.3683.0606.7345.1453 1.0933.1327.2887.3022.5593.5043.8052.271.2134.6129.3183.9582.2939z" />
        <path d="m60.1137 44.1332-1.8273-6.1041h1.2488l1.1866 4.5112h.0712l1.1865-4.4935h1.2459l1.1865 4.4377h.0712l1.1866-4.4377h1.2488l-1.8273 6.104h-1.3141l-1.2458-4.3671h-.0742l-1.2459 4.3671z" />
        <path d="m70.9707 46.4608v-8.4317h1.1866v1.0198h.0711c.0706-.1603.1712-.306.2967-.429.1551-.1574.3238-.301.5043-.4291.2887-.1696.6237-.2457.9581-.2175.4645.0001.9205.1239 1.32.3586.4114.274.742.6513.9582 1.0932.2404.5258.3648 1.0964.3648 1.6737s-.1244 1.1479-.3648 1.6737c-.2126.4236-.5163.7958-.8899 1.0903-.3981.2357-.8534.3596-1.3171.3586-.3117.0197-.6222-.0551-.8899-.2146-.2124-.122-.4112-.2658-.5933-.429-.125-.1235-.2256-.269-.2966-.4291h-.0742v3.1505zm1.1866-5.3723c-.0195.3765.0549.752.2165 1.0933.1137.2782.2867.5289.5073.7347.2586.1871.5696.2898.8899.2939.1608.0153.3231-.003.4763-.0536.1533-.0506.2942-.1325.4136-.2403.257-.2038.4351-.4893.5043-.8082.145-.3454.2186-.7162.2165-1.0903-.0024-.3688-.0502-.7359-.1424-1.0933-.1133-.2776-.2864-.5273-.5072-.7318-.2514-.1913-.5596-.2951-.8766-.2951s-.6252.1038-.8766.2951c-.2531.1743-.4326.4348-.5042.7318-.2245.3499-.3285.7623-.2967 1.1756z" />
        <path d="m79.6266 44.2743c-.3547.0021-.7056-.071-1.0294-.2146-.3044-.1324-.558-.358-.7238-.6436-.1883-.3078-.2907-.6598-.2966-1.0198-.0185-.3091.0581-.6165.2195-.8816.1299-.2006.3019-.371.5043-.4996.2308-.1346.4808-.2337.7416-.2939.2877-.0917.5878-.1393.8899-.1411.2935-.0531.5916-.0777.8899-.0735.178-.0003.3525-.0491.5043-.141.0452-.0349.0818-.0794.1069-.1304.0252-.0509.0384-.1068.0385-.1635.0163-.1478-.0019-.2973-.0533-.437-.0515-.1397-.1348-.2657-.2434-.3683-.1182-.1096-.2589-.1925-.4125-.2433-.1536-.0507-.3164-.068-.4774-.0506-.3452-.0243-.6871.0806-.9581.2939-.1889.1775-.358.3745-.5043.5878l-1.1035-.2145c.1123-.3423.3176-.6473.5933-.8817.2797-.1945.5777-.3618.8899-.4996.337-.0841.682-.1324 1.0293-.144.2728-.0032.5451.0215.8128.0735.2813.0671.554.1657.8128.2938.2464.1607.4458.3827.5784.6437.1616.3413.236.7167.2166 1.0932v3.9205h-1.1866v-.8053h-.0711c-.0961.1618-.2184.3067-.3619.4291-.1629.1615-.3607.2842-.5785.3586-.2591.1172-.5433.1697-.8276.1528zm.2966-.9287c.3098.0007.6151-.0729.8899-.2145.2182-.1096.3951-.2858.5043-.5026.141-.1855.217-.4114.2166-.6436v-.8053c-.0712.0706-.1424.0706-.2166.1411-.1453 0-.2966.0735-.4331.0735-.1364 0-.2966.0705-.4331.0705-.1256-.022-.2551.0042-.3619.0735-.2274.0197-.4516.0671-.6674.141-.0888.0176-.1732.0527-.2481.1031-.0749.0505-.1388.1154-.188.1908-.0901.1516-.1392.3237-.1423.4996-.0095.1238.0127.248.0646.361.0518.113.1316.2113.232.2856.235.1558.5123.2368.795.2322z" />
        <path d="m84.2452 44.1332v-6.1041h1.1866v1.0198h.0712c.1096-.3184.3106-.5983.5784-.8052.2761-.2037.6143-.3074.9582-.2939h.4687v1.1756c-.0392.0059-.0792.0026-.1168-.0097-.0376-.0122-.0718-.0331-.0998-.0609h-.3471c-.2541.0018-.5056.0506-.7415.144-.2034.1285-.3764.2989-.5073.4996-.1513.2149-.2273.473-.2165.7348v3.7059z" />
        <path d="m91.7116 38.0291v.9493h-3.3787v-.9493zm-2.4235-1.4518h1.1865v5.7426c0 .2146 0 .4291.0742.4996.0712.144.1424.2146.2966.2146.1106.0595.2366.085.3619.0734h.3619l.2166.9464c-.0742 0-.1454.0734-.2967.0734s-.2966.0706-.5043.0706c-.3016-.0105-.6006-.0589-.8899-.144-.2587-.1127-.4873-.2838-.6674-.4997-.1625-.2893-.2377-.6188-.2166-.9492v-6.0277z" />
        <path d="m98.5253 44.2743c-.5331.0013-1.0592-.1205-1.5366-.3556-.4139-.292-.7641-.6639-1.0293-1.0933-.2534-.5177-.3775-1.0882-.3619-1.6634.0019-.5744.1252-1.142.3619-1.6663.2018-.4729.5666-.8593 1.0293-1.0904.464-.2702.9997-.3952 1.5366-.3585.5325-.0014 1.0579.1215 1.5337.3585.415.29.767.6611 1.032 1.0904.241.5258.365 1.0964.365 1.6736 0 .5773-.124 1.1479-.365 1.6737-.218.4639-.579.8469-1.032 1.0933-.4778.2292-1.0029.345-1.5337.338zm0-1.0022c.3439.0133.682-.0904.9582-.2939.2564-.2031.4344-.4874.5042-.8052.0943-.357.1433-.7243.1453-1.0933-.011-.3674-.059-.7327-.1453-1.0903-.0737-.3162-.251-.5994-.5042-.8053-.2699-.2154-.6127-.3206-.9582-.2939-.3364-.0242-.6703.0733-.9397.2743-.2695.201-.4562.492-.5257.819-.0922.3563-.14.7225-.1424 1.0903.0124.3681.0601.7342.1424 1.0933.0836.3128.2604.5934.5073.8052.2803.1959.6151.3007.9581.2998z" />
        <path d="m105.645 38.0291v.9493h-3.453v-.9493zm-2.566 6.1041v-6.8094c-.019-.3303.056-.6592.216-.9492.163-.2542.394-.4579.668-.5878.275-.1416.58-.2152.89-.2146.2-.0241.404.0011.593.0735.113.0504.235.0745.359.0705l-.297.9493c-.039.0063-.08.0029-.117-.0099-.038-.0128-.072-.0347-.099-.0636h-.297c-.297 0-.504.0735-.578.2175-.079.0869-.139.1888-.176.2996-.037.1107-.051.2278-.041.344v6.6772z" />
      </g>
      <path
        d="m139.954 34.8434v10.5947h3.215v-1.5929h-1.011v-10.5947h-2.204z"
        fill="#ff282d"
      />
      <path
        d="m112.165 43.8452h1.103v-5.0843h-1.103v-1.3783l1.103-.1411v-1.4518c0-1.7339.362-2.4686 2.566-2.4686.515.0108 1.029.0589 1.537.144l-.297 1.7339c-.244-.0506-.492-.0743-.741-.0705-.813 0-.956.0705-.956.8052v1.2344h1.608v1.5928h-1.593v5.0843h1.101v1.5929h-4.328z"
        fill="#ff282d"
      />
      <path
        d="m138.853 43.3456c-.268.0496-.54.0732-.813.0705-.889 0-.815-.2939-.815-1.0903v-3.5649h1.682v-1.5928h-1.682v-3.9205h-2.13v9.4338c0 1.8985.433 2.6861 2.492 2.6861.54-.0366 1.077-.1082 1.607-.2145z"
        fill="#ff282d"
      />
      <path
        d="m152.175 43.8452c.161 0 .318.0472.452.1357s.238.2143.299.3614c.062.1471.078.309.046.4653-.031.1562-.108.2996-.222.4123-.114.1126-.258.1893-.416.2203-.158.0311-.321.0152-.47-.0458-.148-.0609-.275-.1641-.364-.2966-.09-.1324-.137-.2881-.137-.4473.005-.212.092-.4138.243-.5637.152-.1499.355-.2364.569-.2416zm0 1.4518c.088 0 .175-.0172.256-.0506.082-.0334.155-.0824.217-.1441s.111-.135.145-.2155c.033-.0806.05-.1669.05-.254 0-.086-.018-.1712-.051-.2504-.034-.0793-.084-.151-.146-.211s-.136-.1071-.217-.1384c-.08-.0312-.167-.0462-.254-.0438-.088-.0004-.175.0164-.256.0495-.081.033-.155.0817-.218.1431-.062.0615-.111.1345-.145.2149s-.051.1667-.051.2538c.007.1723.08.3355.205.4558s.291.1886.465.1907zm.163-.2939-.145-.2145h-.145v.2145h-.146v-.8052h.362c.217 0 .297.0705.297.2939 0 .141-.074.2145-.145.2145l.216.2939zm-.219-.3585h.145c.074 0 .146 0 .146-.1411-.002-.0375-.018-.0729-.045-.0995-.027-.0265-.063-.0424-.101-.0445h-.145z"
        fill="#ff282d"
      />
      <path
        d="m132.401 38.6904v-.2939c-.528-.1114-1.068-.1598-1.607-.144-1.03 0-1.101.4996-1.101.8053 0 .429.143.6436 1.246.8816 1.608.3586 3.162.7347 3.162 2.6862 0 1.8074-.958 2.7566-2.999 2.7566-1.237-.041-2.467-.2084-3.669-.4996v-1.6311h1.605v.2939c.608.1112 1.226.1585 1.845.1411 1.1 0 1.246-.5701 1.246-.8817 0-.429-.297-.6612-1.32-.8052-1.896-.2939-3.379-.9493-3.379-2.8272 0-1.8104 1.249-2.4716 3.215-2.4716 1.141.0145 2.275.1644 3.379.4467v1.5929z"
        fill="#ff282d"
      />
      <path
        d="m122.512 40.1393-.143-.1411-.813.7318h-.145c-.113.0082-.218.0565-.298.1358-.08.0794-.128.1846-.135.2962-.005.0546.002.1096.019.1615.018.052.047.0997.084.1401.037.0403.083.0725.134.0945.05.022.105.0332.16.033h.036c.113-.0044.221-.051.301-.1305.081-.0796.128-.1862.132-.2986v-.144z"
        fill="#ff282d"
      />
      <path
        d="m125.807 43.3456v-6.6096h-2.133v.6437c-.452-.2678-.946-.4604-1.462-.5702v-.7347h.297v-.5143h-2.13v.5025h.296v.7318c-1.074.2021-2.033.7937-2.691 1.6592-.657.8656-.965 1.9428-.863 3.0213.101 1.0785.605 2.0807 1.413 2.8108.807.7301 1.861 1.1356 2.954 1.1373.806.0045 1.596-.2188 2.278-.6436l.359.6436h2.278v-2.0925h-.578zm-4.245 0v-.2146h-.296v.2146c-.552-.0407-1.071-.2764-1.463-.6642-.391-.3878-.629-.902-.67-1.4489h.219v-.2939h-.219c.025-.5523.258-1.0753.653-1.4663.394-.3909.922-.6216 1.48-.6467v.2145h.296v-.2145c.542.0332 1.054.2568 1.445.6307.39.3738.633.8734.685 1.4089v.0734h-.296v.2939h.296v.0706c-.058.5334-.303 1.0297-.692 1.4029-.389.3731-.898.5994-1.438.6396z"
        fill="#ff282d"
      />
      <path
        d="m148.616 37.1681h4.39v1.5928h-1.029l-2.712 6.6066c-.812 1.8986-2.058 3.6325-4.028 3.6325-.515-.0112-1.028-.0583-1.537-.1411l.217-1.9514c.294.0506.592.0743.89.0706.486-.0125.959-.1624 1.362-.4321.403-.2698.72-.648.913-1.0903l-2.791-6.6624h-1.015v-1.6135h4.39v1.5929h-1.029l1.537 3.7794 1.533-3.7794h-1.029z"
        fill="#ff282d"
      />
    </g>
    <path
      d="m13.8708 13.7486v-.9997h12.485v.194c-1.5983.5175-3.9649 1.0054-4.5723 1.0996.3311.1531.439.2882.58.3341.4887.0587.9733.1473 1.451.2655.9402.2151 1.7187.4187 2.3592.6434.6656.2474 1.0178.3614 1.4099.4737.4581.1241.9222.2251 1.3906.3024.3864.0568 1.2078-.2269 1.4007-.2763.193-.0493 2.6915-1.0144 2.6915-1.0144s1.0845-.4034 1.5138-.5106c.3944-.1083.4047.0227.4047.0227.0358.1136.064.2294.0845.3467.0398.3692.0432.7415.0102 1.1114-.0425.2851-.0997.5678-.1712.847-.0646.2307-.1516.4545-.2597.6684-.0799.139-.2032.3557-.2455.4499-.0653.1296-.1074.2696-.1244.4136-.0196.1949-.0102.3916.028.5838.0342.1645.1449.577.1449.577l.1308.5157-17.893-.0216v1.0428h-14.49022c.00794-.024.00932-.0496.004-.0743-.01484-.0635-.08791-.1572-.36761-.3245-.36019-.2332-1.497259-.6423-1.82662162-.762-.01312885-.3103-.14841338-1.74 1.64624162-3.3087.53731-.4564 1.10276-.8789 1.69305-1.2652 0 0 1.54122-1.002 2.7662-1.4019"
      fill="#2800ff"
    />
    <path
      d="m34.5798 19.7965-17.8929-.0216v1.0428h-14.48742c-.08049.2349-1.1679.6502-1.912249.7943.399574.5464 1.154199 1.0451 1.911679 1.4751.73003.396 1.48775.7394 2.26729 1.0275.28541.0981 1.13593.4289 1.64282.4686.7053.05 1.4137.0367 2.1166-.0397.3715-.0359.74065-.0928 1.10568-.1702 0 0 .07078.278.51374.8885.32026.4226.71296.855 1.10506 1.1108.467.2934.6222.4482 1.6337.6315.7523.1425 1.521.178 2.2833.1055.5137-.0471 1.0115-.0635 1.4539-.1855.0211-.5639-.0502-1.0848-.2786-1.5449.6142.0244.9556.042 1.813.0255 1.6553-.0312 3.4631-.3699 4.945-.6513 1.2244-.232 3.2274-.7897 4.1852-1.1063.5503-.1821 1.8586-.6241 1.9802-.6241.1969 0 .4926.046.9042.1668.6113.1799 1.0811.3404 2.112.7086s1.9482.6894 2.5065.7654c.4127.0567.4635 0 .492-.2054.0474-.2598-.3168-.851-.4669-1.3151-.1882-.4689-.2258-.9841-.1079-1.4751.0387-.1444.0856-.2864.1404-.4255.0995-.2832.1429-.5827.1279-.8822-.0114-.1908-.043-.3798-.0942-.564z"
      fill="#5a78ff"
    />
    <path
      d="m34.3463 11.2761c-.0058-.1954.0192-.3905.0742-.5782.0354-.1134.0719-.2144.1393-.4056.0963-.2911.133-.5984.1079-.9038-.0193-.18105-.0492-.36083-.0897-.53841l-13.122-.02213v1.01556h-19.24801c.03881.27858-1.17018.72398-1.914533.87028.388157.5299 1.173033 1.0525 1.932793 1.4842.73173.395 1.49051.7383 2.27072 1.0275.3425.1203 1.09141.3971 1.62113.4578l7.7529.0653v-.9997h12.4849v.194c.1884-.0522.4681-.135.6479-.194.5503-.1821 1.8803-.6371 2.0019-.6388.1975 0 .4492.0431.8608.1639.6113.1793 1.0977.3592 2.1297.7251 1.0321.366 1.9277.6808 2.4848.7625.4789.0709.5075-.0885.5075-.2785.0114-.2423-.3494-.8545-.5001-1.3208-.0938-.2862-.1417-.5852-.1421-.8862z"
      fill="#fec1ee"
    />
    <path
      d="m35.0695 3.7394c.0102.18495.0388.93953.0102 1.11087-.0372.2619-.0906.52129-.1598.7767-.0742.23261-.1872.56735-.274.7194-.0799.139-.21.35345-.2523.44763-.0626.13818-.1023.28551-.1176.43629-.0196.19491-.0102.39163.028.58381.0343.16453.145.57699.145.57699l.129.45899-13.122-.02213v1.01555h-19.24805s.01713-.11347-.33107-.32906c-.31395-.1702-1.335721-.60139-1.8551668-.76025-.0502321-.25927-.1609712-1.72757 1.6336868-3.29573.53751-.45676 1.10335-.87951 1.69419-1.26575 0 0 1.4887-1.05016 3.08814-1.5205.38552-.10812.77855-.18777 1.17588-.23828 1.13422-.09191 2.95229-.13617 3.13039-.12993.3567-.47192.745-.91944 1.1622-1.339507.6775-.663231 1.0617-.9191051 1.467-.964493.5594.0329062 3.5539.728476 4.3993.978677.4852.136163 1.4567.397143 2.2262.717693.7934.30297.9864.56054.9864.56054.1486.22319.3098.4378.4829.64281.1872.18609.7249.50721.8745.56735.2254.05673.6068.06014 1.4698.26722.9019.21332 1.7125.4414 2.3575.64111.6679.22126 1.0161.37445 1.4157.47657.3995.10212 1.0388.25531 1.3928.30637.3539.05106 1.1319-.22694 1.3248-.2763.193-.04936 2.7634-1.00137 2.7634-1.00137s1.3871-.49303 1.5126-.53331c.4207-.13617.4904-.14581.4904.39204z"
      fill="#ff7698"
    />
    <path
      d="m6.10775 5.3053c-.90761 0-1.59259.69216-1.63825 1.67368-.04567.97016.73635 1.67368 1.63825 1.67368.90189 0 1.63825-.7489 1.63825-1.67368s-.73008-1.67368-1.63825-1.67368zm-.38245 2.9956c-.6964 0-1.26151-.49359-1.2558-1.28788.02283-.65245.56511-1.28788 1.2558-1.28788s1.2558.57302 1.2558 1.28788-.55883 1.28788-1.25523 1.28788z"
      fill="#fff"
    />
    <path
      d="m5.72584 5.73023c-.6964 0-1.23868.63543-1.25581 1.28788 0 .79429.56511 1.28789 1.25581 1.28789.69069 0 1.2558-.57303 1.2558-1.28789s-.5594-1.28788-1.2558-1.28788zm-.59366 2.14458c-.08399.00226-.16675-.02043-.2377-.06517s-.12687-.10949-.16059-.18598c-.03373-.07649-.04374-.16124-.02876-.24342.01499-.08217.05429-.15803.11289-.21789.05859-.05986.13381-.10098.21605-.11812.08223-.01714.16774-.00952.2456.0219.07785.03141.14451.08519.19144.15446.04694.06926.07203.15087.07206.23438.00099.05448-.00889.10861-.02905.15928-.02015.05067-.0502.09687-.0884.13595s-.08381.07026-.13419.09174c-.05037.02148-.10453.03285-.15935.03344z"
      fill="#000"
    />
    <path
      d="m5.1323 7.87536c.22699 0 .41099-.18797.41099-.41983 0-.23187-.184-.41984-.41099-.41984-.22698 0-.41099.18797-.41099.41984 0 .23186.18401.41983.41099.41983z"
      fill="#fff"
    />
    <path
      d="m6.10775 16.288c-.90761 0-1.59259.6922-1.63825 1.6737-.04567.9702.73635 1.6737 1.63825 1.6737.90189 0 1.63825-.7489 1.63825-1.6737s-.73008-1.6737-1.63825-1.6737zm-.38245 2.9956c-.6964 0-1.26151-.4936-1.2558-1.2879.02283-.6524.56511-1.2878 1.2558-1.2878s1.2558.573 1.2558 1.2878c0 .7149-.55883 1.2879-1.25523 1.2879z"
      fill="#fff"
    />
    <path
      d="m5.72584 16.7135c-.6964 0-1.23868.6355-1.25581 1.2879 0 .7943.56511 1.2879 1.25581 1.2879.69069 0 1.2558-.573 1.2558-1.2879 0-.7148-.5594-1.2879-1.2558-1.2879zm-.59366 2.1446c-.08399.0023-.16675-.0204-.2377-.0651-.07095-.0448-.12687-.1095-.16059-.186-.03373-.0765-.04374-.1613-.02876-.2434.01499-.0822.05429-.1581.11289-.2179.05859-.0599.13381-.101.21605-.1182.08223-.0171.16774-.0095.2456.0219.07785.0315.14451.0852.19144.1545.04694.0693.07203.1509.07206.2344.00091.0544-.00902.1085-.02921.1591s-.05025.0967-.08845.1357c-.03819.0391-.08377.0702-.13411.0916-.05034.0215-.10445.0328-.15922.0334z"
      fill="#000"
    />
    <path
      d="m5.1323 18.8581c.22699 0 .41099-.1879.41099-.4198s-.184-.4199-.41099-.4199c-.22698 0-.41099.188-.41099.4199s.18401.4198.41099.4198z"
      fill="#fff"
    />
    <path
      d="m12.8127 9.84351c-.2346.87539-.4783 1.59599-.4829 1.60899-.0168.0494-.0237.1017-.0202.1538.0035.052.0173.1029.0405.1497.0233.0468.0556.0886.0951.1229.0395.0344.0855.0607.1352.0774s.1023.0236.1547.0201.1036-.0171.1506-.0403c.0471-.0231.0891-.0552.1237-.0945s.0611-.0849.0779-.1343c.0194-.0568.3002-.8879.5525-1.86379z"
      fill="#5a78ff"
    />
    <path
      d="m10.9734 9.84351c-.0731.16399-.1256.26439-.1284.27009-.0389.0794-.0452.1706-.0176.2545s.0869.1539.1654.1952.17.0507.2553.0261c.0853-.0245.1577-.081.2019-.1575.099-.1916.1881-.3879.2672-.58839z"
      fill="#5a78ff"
    />
    <path
      d="m13.2374 7.66772c0 .01191-.0034.02326-.0046.03517-.0457.62012-.2283 1.43143-.4201 2.14061h.83c.1781-.68876.3425-1.45071.3887-2.06628.1016-.68593-.3202-1.97495-.5994-2.72782 2.3904.09059 4.7744.30591 7.1421.64508 4.0568.58323 8.9505 3.00014 8.9996 3.02454.047.02442.0985.03929.1514.04373.0528.00444.1061-.00163.1566-.01787.0505-.01623.0972-.0423.1374-.07669.0403-.03438.0732-.07639.097-.12357.0237-.04718.0378-.09858.0413-.15121.0035-.05264-.0035-.10544-.0208-.15533-.0172-.04989-.0443-.09587-.0796-.13525-.0353-.03939-.0781-.07139-.126-.09413-.2044-.10213-5.0563-2.49634-9.2421-3.10057-4.3383-.62408-7.8083-.66834-7.8425-.66834-.0661-.00074-.1313.0148-.1898.04523-.0585.03044-.1085.07482-.1456.12917-.037.05435-.0599.11699-.0667.18229-.0067.06531.003.13126.0282.19194.3413.82833.8539 2.33861.7649 2.8793z"
      fill="#2800ff"
    />
    <path
      d="m11.449 7.74882c.0668.75571-.2786 1.65439-.4755 2.09465h.7421c.2192-.54693.4817-1.3883.4138-2.15593-.0961-1.02891-.445-2.01861-1.016-2.88213-.0263-.03636-.0595-.06722-.0977-.09084-.0382-.02361-.0808-.0395-.1252-.04677s-.0898-.00578-.1337.0044c-.0438.01018-.0852.02884-.1218.05492-.0366.02607-.0676.05906-.0914.09706-.0237.03801-.0397.08029-.047.12444-.0074.04415-.0058.0893.0044.13287s.029.08471.0552.12106c.4974.76598.8035 1.63896.8928 2.54627z"
      fill="#2800ff"
    />
    <path
      d="m12.82 20.8155c-.2346.8754-.4783 1.5959-.4829 1.609-.033.0995-.0251.2079.022.3017.047.0938.1295.1652.2293.1988.0999.0336.2091.0267.3038-.0194.0947-.046.1672-.1274.2018-.2264.02-.0567.3009-.8879.5526-1.8637z"
      fill="#fec1ee"
    />
    <path
      d="m10.9809 20.8155c-.0737.1639-.1262.2644-.129.27-.0425.0799-.0513.1732-.0246.2595.0268.0863.087.1584.1673.2007.0803.0422.1742.0509.261.0243s.1594-.0864.2019-.1662c.0989-.1915.1881-.3879.2671-.5883z"
      fill="#fec1ee"
    />
    <path
      d="m13.2431 18.6397c-.002.0116-.0033.0234-.004.0352-.0463.6201-.2284 1.4314-.4201 2.1406h.8265c.1781-.6888.3425-1.4507.3893-2.0663.1016-.6859-.3202-1.975-.5999-2.7278 2.3906.0906 4.7747.3059 7.1426.645 4.0563.5833 8.9505 3.0002 8.999 3.0246.0469.0233.098.0373.1504.041.0523.0037.1049-.0028.1547-.0193.0498-.0164.0958-.0425.1355-.0766.0397-.0342.0722-.0758.0957-.1224.0235-.0467.0375-.0974.0413-.1495.0037-.052-.0029-.1042-.0194-.1537-.0166-.0495-.0428-.0953-.0772-.1347-.0343-.0395-.0762-.0718-.1231-.0951-.2043-.1022-5.0563-2.4964-9.2427-3.1006-2.5986-.3763-5.2174-.5997-7.8425-.6689-.0661-.0007-.1313.0149-.1898.0454s-.1085.075-.1455.1294-.0598.1171-.0664.1824c-.0067.0654.0031.1313.0284.192.3453.8261.8579 2.338.7672 2.8793z"
      fill="#ff7698"
    />
    <path
      d="m11.4556 18.7225c.0674.7557-.2785 1.6544-.4749 2.0947h.7421c.2186-.5469.4818-1.3883.4138-2.1559-.0961-1.029-.445-2.0187-1.016-2.8822-.053-.0734-.1332-.1229-.2229-.1376s-.1816.0067-.2555.0593c-.0739.0527-.1237.1324-.1384.2215-.0148.0892.0067.1805.0596.254.4971.766.803 1.639.8922 2.5462z"
      fill="#ff7698"
    />
    <g fill="currentColor">
      <path d="m55.687 12.5305v3.8228h5.8258c-.1501 4.2891-3.0721 6.5636-7.2123 6.5636-5.6756 0-8.0919-4.2698-8.0919-9.5099 0-4.88542 2.4163-9.26535 8.0172-9.26535 3.3718 0 6.1443 1.60389 7.3635 5.18386l4.215-1.75254c-2.1378-4.92515-6.3339-7.57297-11.5409-7.57297-8.7484 0-12.7755 6.377-12.7755 13.5937 0 7.2348 4.1025 13.5562 12.8126 13.5562 3.522 0 6.4069-1.6039 7.8305-4.0849l.4116 3.7139h3.7674v-14.2484z" />
      <path d="m75.2057 27.1499c1.0845 0 2.4545-.1867 3.4095-.3728v-3.8228c-.4156.0656-.8352.1035-1.2558.1135-.9179 0-1.4984-.3727-1.4984-1.2482v-21.390714h-4.5706v22.637214c.0006 3.2821 1.761 4.0838 3.9153 4.0838z" />
      <path d="m82.043 4.62443h4.7949v-4.195544h-4.7949zm.1141 22.15267h4.5706v-18.6283h-4.5706z" />
      <path d="m98.8085 27.1499c1.3695 0 2.7155-.2984 3.4085-.5594v-4.1235c-.618.2985-1.648.6718-2.6031.6718-1.1987 0-2.0795-.522-2.0795-2.3125v-8.8734h4.6806v-3.80404h-4.6806v-7.869128l-4.4957 3.244668v4.62446h-3.0905v3.80404h3.0157v9.6773c0 3.3939 2.1914 5.5197 5.8446 5.5197z" />
      <path d="m113.663 27.1499c3.71 0 6.631-2.0509 7.867-5.5197l-4.158-1.5659c-.487 1.7526-1.911 2.965-3.747 2.965-3.184 0-4.383-2.704-4.383-5.5385 0-2.78 1.236-5.6128 4.383-5.6128 1.874 0 3.26 1.2681 3.747 3.058l4.346-1.6782c-1.424-3.71044-4.383-5.50326-8.093-5.50326-6.107 0-9.028 4.84796-9.028 9.73346 0 4.9444 2.959 9.6619 9.066 9.6619z" />
      <path d="m125.09 26.7788h4.645v-9.4372c0-3.0955 1.386-5.4636 4.308-5.4636 2.754 0 3.372 1.9954 3.372 4.2886v10.6122h4.646v-11.1898c0-3.8977-2.005-7.8294-6.912-7.8294-1.037-.00301-2.062.22472-2.998.6665-.937.44179-1.763 1.08641-2.416 1.8866v-9.883814h-4.645z" />
    </g>
  </svg>
);
